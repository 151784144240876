document.addEventListener('DOMContentLoaded', function(){
   var photons = document.getElementsByClassName('photon'),
  l = photons.length, i, cur;

   for (i = 0; i < l; i++) {
    cur = photons[i];

    var a = 10 * Math.random() + 5;
    cur.setAttribute('Style', 'animation: spin ' + a + 's linear infinite;');
   }
});


